<template>
  <v-card class="d-flex flex-grow-1 align-center pa-2 mt-1 mr-4 ml-n1 audit-panel">
    <v-icon class="mr-2">{{$icon('i.Audit')}}</v-icon>
    <component :is="computedAuditComponentName" />
    <v-spacer />
    <v-btn
      fab
      x-small
      color="error"
      class="ml-2"
      @click="stop"
    >
      <v-icon>{{$icon('i.Close')}}</v-icon>
    </v-btn>
  </v-card>

</template>

<script>

import auditController from '@/auditController'

export default {
  components: {
    Picker: () => import('@/components/picker'),
    EscalationProcessorRunAudit: () => import('../pages/escalation-protocols/audit/escalation-processor-run-audit')
  },
  computed: {
    computedAudit: {
      get () {
        return this.auditController.dataAudit
      },
      set (v) {
        this.auditController.dataAudit = v
        this.getAudit()
      }
    },
    computedAuditComponentName () {
      return this.computedAudit?.source?.escalationProcessorRunId ? 'escalation-processor-run-audit' : null
    }
  },
  data () {
    return {
      auditController: auditController
    }
  },
  methods: {
    stop () {
      auditController.stopAudit()
    }
  }
}
</script>

<style lang="stylus" scoped>
.audit-panel
  border 1px solid var(--primary-color)
  max-width 50%
  height 44px
</style>
